.single {
  display: flex;
  width: 100%;
  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        height: fit-content;
        position: relative;

        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 12px;
          color: #7451f8;
          background-color: #7551f818;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
        }

        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
            color: #489FC5 !important;
          }

          .details {
            .itemTitle {
              margin-bottom: 10px;
              color: #555;
            }

            .detailItem {
              margin: 10px;
              font-size: 14px;
              width: 50vw;

              .itemKey {
                font-size: 20px;
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-size: 16px;
                font-weight: 300;
              }
            }
          }
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px 20px;
      height: 750px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}

.fieldWithStatus {
  padding: 5px;
  border-radius: 5px;

  &.active {
    background-color: rgba(0, 128, 0, 0.05);
    color: green;
  }
  &.pending {
    background-color: rgba(255, 217, 0, 0.05);
    color: goldenrod;
  }
  &.inactive {
    background-color: rgba(255, 0, 0, 0.05);
    color: crimson;
  }
}

.input-items{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cellAction {
  display: flex;
  align-items: center;
  gap: 15px;

  .viewButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: darkblue;
    border: 1px dotted rgba(0, 0, 139, 0.596);
    cursor: pointer;
  }

  .updateButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: goldenrod;
    border: 1px dotted rgba(243, 208, 10, 0.808);
    cursor: pointer;
  }
  
  .deleteButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: crimson;
    border: 1px dotted rgba(220, 20, 60, 0.6);
    cursor: pointer;
  }
}
.page {
  display: flex;
  width: 100%;
  .listContainer {
    flex: 6;
  }
}

.loader{
  position: absolute;
  top: 50%;
  left: 50%;
}

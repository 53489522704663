.new {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      // .left {
      //   flex: 1;
      //   text-align: center;

      //   img {
      //     width: 100px;
      //     height: 100px;
      //     border-radius: 50%;
      //     object-fit: cover;
      //   }
      // }

      .right {
        position: relative;
        padding-bottom: 80px;
        // flex: 2;

        form {
          display: flex;
          flex-wrap: wrap;
          padding-left: 50px;
          gap: 30px;
          align-items: flex-start;
          justify-content: flex-start;

          .formInput {
            width: 40%;
            padding-left: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            label {
              display: flex;
              font-size: 16px;
              align-items: center;
              // gap: 10px;
              color: #489FC5;

              .icon {
                cursor: pointer;
              }
            }

            input,
            textarea {
              border-radius: 5px;
              box-shadow: none;
              border: 1px solid #ced6e0;
              transition: all 0.3s ease-in-out;
              font-size: 18px;
              padding: 15px 15px;
              background: none;
              color: #1a3b5d;
              font-family: "Source Sans Pro", sans-serif;
              margin-top: 5px;
            }

            select {
              height: 60px;
              border-radius: 5px;
              box-shadow: none;
              border: 1px solid #ced6e0;
              transition: all 0.3s ease-in-out;
              font-size: 18px;
              padding: 5px 15px;
              background: none;
              color: #1a3b5d;
              font-family: "Source Sans Pro", sans-serif;
              margin-top: 5px;
            }
          }

          .formInput-multiple {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 40px;

            label {
              font-size: 16px;
              align-items: center;
              // gap: 10px;
              color: #489FC5;

              .icon {
                cursor: pointer;
              }
            }

            .input-container {
              display: flex;
              flex-direction: row;
              gap: 20px;
            }

            input,
            textarea {
              width: 30%;
              // height: 50px;
              padding-block: 10px !important;
              border-radius: 5px;
              box-shadow: none;
              border: 1px solid #ced6e0;
              transition: all 0.3s ease-in-out;
              font-size: 18px;
              padding: 5px 15px;
              background: none;
              color: #1a3b5d;
              font-family: "Source Sans Pro", sans-serif;
              margin-top: 5px;
            }

            select {
              height: 60px;
              border-radius: 5px;
              box-shadow: none;
              border: 1px solid #ced6e0;
              transition: all 0.3s ease-in-out;
              font-size: 18px;
              padding: 5px 15px;
              background: none;
              color: #1a3b5d;
              font-family: "Source Sans Pro", sans-serif;
              margin-top: 5px;
            }
          }
        }
      }
    }

    .selection-form {
      padding: 0 20px;

      label {
        font-size: 18px;
        align-items: center;
        // gap: 10px;
        color: #489FC5;
      }

      input {
        width: 30%;
        height: 50px;
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid #ced6e0;
        transition: all 0.3s ease-in-out;
        font-size: 18px;
        padding: 5px 15px;
        background: none;
        color: #1a3b5d;
        font-family: "Source Sans Pro", sans-serif;
        margin-top: 5px;
      }

      button {
        background-color: #489FC5;
        border-radius: 4px;
        border-style: none;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: "Farfetch Basis", "Helvetica Neue", Arial, sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
        margin: 0;
        max-width: none;
        min-height: 44px;
        min-width: 10px;
        outline: none;
        overflow: hidden;
        padding: 9px 20px 8px;
        position: relative;
        text-align: center;
        text-transform: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        width: 100px;

        &:hover {
          background-color: #3d87a7;
        }
      }
    }
  }
}

.input-wrapper {
  position: relative;

  input[type="number"] {
    padding-left: 25px;
    text-align: right;
  }

}

.dollar-sign {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  display: block !important;
  color: gray !important;
  font-size: 16px !important;
}


/* RichTextEditor.css */

.editor-container {
  max-width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.editor-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.ck.ck-editor__main > .ck-editor__editable {
  min-height: 200px;
  transition: background-color 0.3s ease;
}

.ck.ck-editor__main > .ck-editor__editable:hover {
  background-color: #f1f1f1;
}

/* Animations */
.ck.ck-toolbar .ck-button {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.ck.ck-toolbar .ck-button:hover {
  background-color: #489fc5;
  color: #fff;
}

.ck-editor__editable_inline {
  min-height: 300px;
  border-radius: 5px;
}

.editor-container .ck-toolbar {
  border-bottom: 1px solid #ddd;
}

.editor-container .ck-editor__top {
  background-color: #fff;
}

.editor-container .ck-toolbar button:hover {
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.editor-container .ck-toolbar .ck-button.ck-on {
  background-color: #e0e0e0;
}

.editor-container .ck-content a {
  color: #489fc5;
  text-decoration: underline;
}

.editor-container .ck-content a:hover {
  color: #489fc5;
}

.editor-container .ck-content p,
.editor-container .ck-content h1,
.editor-container .ck-content h2,
.editor-container .ck-content h3,
.editor-container .ck-content h4 {
  transition: color 0.3s ease;
}

.editor-container .ck-content p:hover,
.editor-container .ck-content h1:hover,
.editor-container .ck-content h2:hover,
.editor-container .ck-content h3:hover,
.editor-container .ck-content h4:hover {
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .editor-container {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .editor-container {
    padding: 5px;
  }
}

.ck.ck-dropdown__panel .ck-list {
  max-height: 300px; /* Set the maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
}
